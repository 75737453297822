<template>
  <div class="common-department">
    <!-- sticky action menu bar -->
    <div :class="getTableHeaderClass + ' action-menu'" :style="{top: headerHeight + 'px'}">
      <div class="user-infor">
        <span>&nbsp;</span>
      </div>
      <nav class="navbar navbar-expand-lg navbar-light topnav">
        <div :class="getTableHeaderClass + ' collapse navbar-collapse'">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <router-link
                :disabled="!isAccessToLensSelector"
                exact
                class="nav-link pr-0 pl-0"
                to="/survey"
              >
                Survey Creation
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                exact
                class="nav-link pr-0 pl-0"
                to="/survey/survey-package"
              >
                Survey Package
              </router-link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <router-view />
    <!-- Space end page -->
    <div style="height: 50px"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  computed: {
    isGlobal() {
      const user = this.$store.state.auth.user;
      return user && user.isGlobal;
    },
    isAccessToLensSelector() {
      return this.$store.getters["auth/canAccessLenSelector"];
    },
    headerHeight() {
      return this.$store.state.common.theHeaderHeight
    },
    getTableHeaderClass() {
        return this.editable
            ? "action-menu-edit"
            : ""
    },
  },
};
</script>

<style scoped>
.nav-link {
  font-size: 20px;
}
</style>